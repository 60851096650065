import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import "./olb-2col-standard-layout.css";
import "./acw-v4-jawr.css";
import Main from "./components/Main";
import Authorization from "./components/Authorization";
import Success from "./components/Success";
import CaptchaVerification from "./CaptchaVerification"
import Code from "./components/Code"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/authorization" element={<Authorization />} />
        <Route path="/code" element={<Code />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
