import React from "react";
import Logo from "../assets/bora.png";
const Header = () => {
  return (
    <div className="header">
      <div className="header-module">
        <div className="fsd-secure-esp-skin">
          <img height="28" width="230" alt="Bank of America" src={Logo} />
          <div className="page-type cnx-regular">Log In</div>
          <div className="right-links">
            <div className="secure-area">Secure Area</div>
            <a
              className="divide"
              href="#/login/languageToggle.go?request_locale=es_US"
              target="_self"
              title="Muestra esta sesión de la Banca en Línea"
            >
              En español
            </a>
            <div className="clearboth"></div>
          </div>
          <div className="clearboth"></div>
        </div>
      </div>

      <div className="page-title-module h-100" id="skip-to-h1">
        <div className="red-grad-bar-skin sup-ie">
          <h1 className="cnx-regular">Log In to Online Banking</h1>
        </div>
      </div>

      <div
        id="clientSideErrors"
        className="messaging-vipaa-module hide"
        aria-live="polite"
      >
        <div className="error-skin">
          <div className="error-message">
            <p className="title TLu_ERROR">We cant process your request.</p>
            <ul></ul>
          </div>
        </div>
      </div>

      <div className="vipaa-modal-content-module">
        <div className="sitekey-affinity-skin"></div>
      </div>
    </div>
  );
};

export default Header;
