import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-top">&nbsp;</div>
      <div className="footer-inner" style={{ marginTop: "202.3px" }}>
        <div className="global-footer-module">
          <div className="gray-bground-skin cssp">
            <div className="secure">Secure area</div>

            <p>
              Bank of America, N.A. Member FDIC.{" "}
              <a href="#/" target="_blank" rel="noreferrer">
                Equal Housing Lender
              </a>{" "}
              <br />
              ©&nbsp;2023 Bank of America Corporation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
